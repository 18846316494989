import { Link } from "gatsby"
import $ from "jquery"
import OwlCarousel from "react-owl-carousel3"
import React, { useState, useRef } from "react"
import { useLocation } from "@reach/router"
import Slider from "react-slick"

const settingsSlick = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

import { connect } from "react-redux"

import {
  getFavoritesAction,
  clearFavoritesAction,
  deleteFavoriteAction,
  changeSelectedAction,
  updaterFavoritesAction,
  REDUX_UPDATE_FILTERS,
} from "../redux/propertiesDucks"

//Helpers
import {
  getCover,
  getTextShort,
  getPreviewSlider,
  getQuality,
} from "../helpers/helper.rendering"
import {
  addFavorites,
  getfavorites,
  checkFavorite,
} from "../helpers/helper.favorites"
import {
  getOperations,
  getType,
  getEnvironment,
  getSurface,
  getLocation,
  getFakeAddres,
  getPrices,
  makeLink,
  getNameProducer,
  getPictureProducer,
  getCellphoneProducer,
  getEmailProducer,
} from "../helpers/helper.properties"

import toast, { Toaster } from "react-hot-toast"
import { graphql, useStaticQuery } from "gatsby"
import { getTitle } from "../helpers/helper.developments"
import { updateRouter } from "../helpers/helper.filters"
import { addMarker } from "../helpers/helper.map"

const Card = props => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
        template_version
        proFeatures {
          lowPrice
        }
        design {
          icons
        }
      }
    }
  `)

  const {
    design: { icons },
  } = realEstate
  const iconsStyle =
    icons === "Solid" || icons === "Light" ? `-${icons.toLowerCase()}` : ""
  const { template_version, proFeatures } = realEstate
  const MediaPro = template_version === "PRO" ? true : false
  const { lowPrice } = proFeatures
  const location = useLocation()
  const history = useLocation()

  const {
    property,
    settings,
    dispatch,
    skeleton,
    filters,
    setZoom,
    map,
    handlerClick,
  } = props
  const { allPrice } = props
  const { operationPrice } = props
  const opacity = props?.opacity

  // Condition to show/hide UI elements on cards. Only apply for home page
  // Has filter value from properties.jsx component
  const showByFilter = props?.showByFilter

  // Wrapper elements to avoid duplicate code on conditional
  // Badge Wrapper
  const badgeWrapper = (index, operation) => (
    <div key={index} className={"badge " + operation.toLowerCase()}>
      {operation}
    </div>
  )
  // Price Wrapper
  const priceWrapper = (index, price) => (
    <div className="d-block mt-2 mt-lg-2" key={index}>
      <h3 class={"prop-price mb-1 " + price.type + (lowPrice ? "" : " d-none")}>
        <div className="d-flex align-items-center">
          <div
            className={
              "previous align-items-center me-3 " +
              (price.is_less_than_previous_price ? "d-flex" : "d-none")
            }
          >
            {" "}
            <div className="inline-block text-through">
              {price.currency}{" "}
              {price.previous_price > 0
                ? Intl.NumberFormat("de-DE").format(price.previous_price)
                : price.previous_price}{" "}
            </div>{" "}
            <i className="icon-arrow-stick-solid rotate-90 pe-1"></i>{" "}
          </div>
        </div>
      </h3>
      <h3 class={"prop-price me-3 mb-0 " + price.type}>
        <div className="d-flex align-items-center">
          <div>
            {price.currency}{" "}
            {price.price > 0
              ? Intl.NumberFormat("de-DE").format(price.price)
              : price.price}
          </div>
          {price.period ? (
            <small className="ms-2"> ( {price.period}) </small>
          ) : (
            ""
          )}
        </div>
      </h3>
    </div>
  )
  // Expense Wrapper
  const expenseWrapper = (index, price, property) => (
    <h3 class={"prop-price me-3 mb-0 " + price.type} key={index}>
      {property.expenses !== 0 && index === 0 && (
        <span>
          {/* <br /> */}
          EXP: ARS {Intl.NumberFormat("de-DE").format(property.expenses)}
        </span>
      )}
    </h3>
  )

  const [listFavorites, setListFavorites] = useState(
    getfavorites("prop", settings.short_name)
  )
  const slider = useRef()

  const Producer_Module = false

  const toastCheck = (id, fake_address) => {
    if (!checkFavorite(id, "prop", settings.short_name))
      return toast.success(fake_address + " agregada a tus favoritos")
    else return toast.success(fake_address + " eliminada de tus favoritos")
  }

  const addHoverMap = property => {
    // $('.marker-' + id).addClass(' hover-marker');
    $("#marker-" + property?.id).addClass(" hover-marker")
    $("#marker-" + property?.development?.id).addClass(" hover-marker")
  }

  const removeHoverMap = id => {
    // $('.marker-' + id).removeClass(' hover-marker');
    $("#marker-" + property?.id).removeClass(" hover-marker")
    $("#marker-" + property?.development?.id).removeClass(" hover-marker")
  }

  const createLinkProducer = producer => {
    let url = ""
    if (updateRouter(filters, true)) {
      const splitter = updateRouter(filters, true)
        ?.split("/")
        .filter(element => !element?.includes("producer") && element !== "")
      for (const iterator of splitter) {
        url += iterator + "/"
      }
      if (producer) {
        return (
          "/" +
          url +
          "producer-" +
          producer?.id +
          "-" +
          producer?.name
            ?.toLowerCase()
            .replaceAll(" ", "-")
            .replaceAll(".", "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
      }
    }
    if (history.pathname.toLowerCase().includes("alquiler")) {
      return (
        "/alquiler/" +
        url +
        "producer-" +
        producer?.id +
        "-" +
        producer?.name
          ?.toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll(".", "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      )
    } else {
      return (
        "/venta/" +
        url +
        "producer-" +
        producer?.id +
        "-" +
        producer?.name
          ?.toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll(".", "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      )
    }
  }

  return (
    <div
      onMouseEnter={() => addHoverMap(property)}
      onMouseLeave={() => removeHoverMap(property)}
      onClick={() =>
        addMarker(property.id) +
        (props.grid_map && dispatch(changeSelectedAction(property.id))) +
        handlerClick?.(property.geo_lat, property.geo_long, map) +
        setZoom?.(15)
      }
      id={"card-grid-" + (props.grid_map && property?.id)}
      class={
        "card prop-card " +
        (skeleton && " skeleton ") +
        (props.inMap ? " map-card " : "") +
        (opacity ? " opacity-card " : "") +
        (props.grid_map ? " grid-card  " : "")
      }
    >
      <div class={"card-image " + (props.grid_map && "grid_map_image")}>
        {props.basic ? (
          <a target="_blank" href={makeLink(property)}>
            <img
              src={getQuality(getCover(property?.photos))}
              className="notScale"
              alt={realEstate?.name}
            />
          </a>
        ) : (
          property?.photos && (
            <>
              <OwlCarousel
                ref={slider}
                items={1}
                margin={0}
                loop={false}
                className="overflow-hidden card-carousel"
              >
                {getPreviewSlider(property?.photos).map((photo, index) =>
                  props.grid_map ? (
                    <img
                      src={index === 0 ? getQuality(photo) : getQuality(photo)}
                      className="notScale"
                      alt={realEstate?.name}
                    />
                  ) : (
                    <a target={"_blank"} href={makeLink(property)}>
                      {
                        <img
                          src={
                            index === 0 ? getQuality(photo) : getQuality(photo)
                          }
                          className="notScale"
                          alt={realEstate?.name}
                        />
                      }
                    </a>
                  )
                )}
              </OwlCarousel>
              {getPreviewSlider(property?.photos)?.length > 1 && (
                <div className="arrows-owl minimal left">
                  <div className="arrow" onClick={() => slider.current.prev()}>
                    {" "}
                    <i></i> <i></i>
                  </div>
                </div>
              )}
              {getPreviewSlider(property?.photos)?.length > 1 && (
                <div className="arrows-owl minimal right">
                  <div
                    className="arrow reverse"
                    onClick={() => slider.current.next()}
                  >
                    {" "}
                    <i></i> <i></i>{" "}
                  </div>
                </div>
              )}
            </>
          )
        )}
        {property && (
          <div class="badges">
            {getOperations(property).map((operation, index) => {
              // Only show badges by filter
              if (showByFilter) {
                if (showByFilter === operation)
                  return badgeWrapper(index, operation)
              } else {
                return badgeWrapper(index, operation)
              }
            })}
          </div>
        )}
        {props.inMap &&
          <div
            onClick={() => dispatch(changeSelectedAction(0))}
            data-bs-dismiss="modal"
            className="close-card"
          >
            <i></i>
            <i></i>
          </div>
         }
        <span
          onClick={() =>
            dispatch(updaterFavoritesAction()) +
            toastCheck(property?.id, property?.fake_address) +
            addFavorites(property?.id, "prop", settings.short_name) +
            setListFavorites(getfavorites("prop", settings.short_name)) +
            dispatch(deleteFavoriteAction(property?.id))
          }
          className={
            (props.grid_map ? "d-none" : "d-lg-inline-flex") +
            ` icon-solid-like btn fav ` +
            (listFavorites.find(
              element => element?.toString() === property?.id?.toString()
            )
              ? "active"
              : "")
          }
          style={{ zIndex: "19" }}
        ></span>
      </div>
      <div class={"card-body " + (props.grid_map && "grid-card-body")}>
        <div class={"content-extra row align-items-center "}>
          <div className={props.grid_map ? "col-12 heighter" : "col-12"}>
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <div
                  class={
                    "extra-data align-items-start justify-content-between mt-4 mb-2"
                  }
                >
                  <span>
                    {getType(property)}{" "}
                    {getEnvironment(property) > 0
                      ? " | " + getEnvironment(property) + " amb"
                      : ""}{" "}
                    {getSurface(property) && " | " + getSurface(property)}
                  </span>
                </div>
                <div className={props.grid_map ? 'd-block' : 'd-none'}>
                  <span
                    onClick={() =>
                      dispatch(updaterFavoritesAction()) +
                      toastCheck(property?.id, property?.fake_address) +
                      addFavorites(property?.id, "prop", settings.short_name) +
                      setListFavorites(
                        getfavorites("prop", settings.short_name)
                      ) +
                      dispatch(deleteFavoriteAction(property?.id))
                    }
                    className={
                      `d-lg-inline-flex icon-solid-like btn fav ` +
                      (listFavorites.find(
                        element =>
                          element?.toString() === property?.id?.toString()
                      )
                        ? "active"
                        : "")
                    }
                    style={{ zIndex: "19" }}
                  ></span>
                </div>
              </div>
              <h3
                class={
                  "card-title mb-2 mb-lg-2 col-12 " +
                  (props.grid_map ? "mt-lg-2 mb-lg-5" : "my-lg-2")
                }
              >
                {!props.grid_map ? (
                  <a target={"_blank"} href={makeLink(property)}>
                    <i className="icon-location-solid"></i>
                    <span>
                      {getFakeAddres(property)}, {getLocation(property)}
                    </span>
                  </a>
                ) : (
                  <a>
                    <span>
                      {getFakeAddres(property)}, {getLocation(property)}
                    </span>
                  </a>
                )}
              </h3>
              <div
                class={
                  "col-12 mb-3 prop-publication-title " +
                  (!props.grid_map ? "d-none" : "mt-lg-5")
                }
              >
                <span>{getTextShort(getTitle(property), 50)}</span>
              </div>
              <div className="d-flex flex-column justify-content-end both-bottom">
                <div className="w-100 price-wrapper align-items-end d-lg-flex">
                  {getPrices(property).map((price, index) => {
                    // Only show prices by filter
                    if (showByFilter) {
                      if (showByFilter === price.type)
                        return priceWrapper(index, price)
                    } else {
                      return priceWrapper(index, price)
                    }
                  })}
                </div>
                <div
                  className={"content-card " + 
                    props.grid_map &&
                    "w-100 d-flex align-items-center justify-content-between mt-auto bottom-wrapper"
                  }
                >
                  <div className="expense-wrapper block">
                    {getPrices(property).map((price, index) => {
                      // Only show expenses by filter
                      if (showByFilter) {
                        if (showByFilter === price.type)
                          return expenseWrapper(index, price, property)
                      } else {
                        return expenseWrapper(index, price, property)
                      }
                    })}
                  </div>
                  <a
                    className="read-more d-flex justify-content-around"
                    target={"_blank"}
                    href={makeLink(property)}
                  >
                    Ver más <i className="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {Producer_Module && (
            <div className="col-4 div_broker_card">
              <a href={createLinkProducer(property?.producer)}>
                {getPictureProducer(property) && (
                  <img
                    className="mb-lg-3 mb-2"
                    src={getPictureProducer(property)}
                    alt=""
                  />
                )}
                {getNameProducer(property) && (
                  <h3 className="contacto mb-1">contacto</h3>
                )}
                <h4 className="name_broker mb-0">
                  {getNameProducer(property)}
                </h4>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    settings: state.settings,
    filters: state.properties.filters,
  }),
  null
)(Card)
